<script>
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import { getAction } from '@/command/netTool'
export default {
  data() {
    return {
      productAvgPrice: 0,
      productList: []
    }
  },
  mounted() {
    // 获取产品列表
    getAction('/api/gxlkoperation/report/productList').then(res => {
      this.productList = res.map((e) => ({ name: e.productName, value: e.productName }))
    })
    // 提交
    getAction('/api/gxlkoperation/report/productAvg').then((e) => {
      this.productAvgPrice = e.productAvgPrice
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '农产品种类',
          type: 'select',
          key: 'productName',
          typeData: this.productList
        },
      ]
    },
    getButton() {
      return [
        {
          name: '导出excel',
          type: 'primary',
          onClick: () => {
            downloadAction('/api/gxlkoperation/report/productPageDownload', '耗电统计.xlsx')
          },
        },
      ]
    },
    getColumns() {
      return [
        {
          title: '农产品种类',
          dataIndex: 'productName',
          filters: [],
        },
        {
          title: '环比上月',
          dataIndex: 'lastMonthRate',
          sorter: true,
        },
        {
          title: '较昨日',
          dataIndex: 'lastDayRate',
          sorter: true,
        },
        {
          title: '农产品均价-元/斤',
          dataIndex: 'avgPrice',
          sorter: true,
        },
      ]
    },
    renderFoot() {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>农产品均价</span>
          <span style={{ color: 'red' }}>{this.productAvgPrice}</span>
          <span>元/斤</span>
        </div>
      )
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        url={'/api/gxlkoperation/report/productPage'}
        tableColumns={this.getColumns()}
        headerData={this.getHeader()}
        button={this.getButton()}
        tableProps={{
          showFootButton: true,
          foot: this.renderFoot,
        }}
      />
    )
  },
}
</script>
